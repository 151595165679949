/* dosis-200normal - latin */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src:
    local('Dosis Extra Light '),
    local('Dosis-Extra Light'),
    url('./files/dosis-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/dosis-latin-200.woff') format('woff'); /* Modern Browsers */
}

/* dosis-300normal - latin */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Dosis Light '),
    local('Dosis-Light'),
    url('./files/dosis-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/dosis-latin-300.woff') format('woff'); /* Modern Browsers */
}

/* dosis-400normal - latin */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Dosis Regular '),
    local('Dosis-Regular'),
    url('./files/dosis-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/dosis-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* dosis-500normal - latin */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Dosis Medium '),
    local('Dosis-Medium'),
    url('./files/dosis-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/dosis-latin-500.woff') format('woff'); /* Modern Browsers */
}

/* dosis-600normal - latin */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Dosis SemiBold '),
    local('Dosis-SemiBold'),
    url('./files/dosis-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/dosis-latin-600.woff') format('woff'); /* Modern Browsers */
}

/* dosis-700normal - latin */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Dosis Bold '),
    local('Dosis-Bold'),
    url('./files/dosis-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/dosis-latin-700.woff') format('woff'); /* Modern Browsers */
}

/* dosis-800normal - latin */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src:
    local('Dosis ExtraBold '),
    local('Dosis-ExtraBold'),
    url('./files/dosis-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/dosis-latin-800.woff') format('woff'); /* Modern Browsers */
}

